import * as R from "ramda";
import { getLocalizedName } from "common";
import { createQueryString } from "common/app/query-string";
import { behaveAs, canDo, getColumn } from "common/entities";
import { Entities, Entity } from "common/entities/types";
import { isFullEntity } from "common/functions/entity";
import { filterFormsByEntity } from "common/functions/forms";
import { QueryForEntity } from "common/query/types";
import { getScheduledEvent } from "common/record/actions/functions/behaviors";
import { Behavior, BehaviorName } from "common/types/behaviors";
import { Context } from "common/types/context";
import { Record } from "common/types/records";
import { functionHandlers } from "../function-handlers";
// eslint-disable-next-line import/no-cycle
import { getActionHandlersByBehaviors } from "../handlers";
import { Action, ActionsGroup } from "../types";
import { getUrlFormId } from "./url";

interface CreateAction {
  column: string;
  entity: Entity;
  isEntityDuplicated?: boolean;
}

type BulkActionType =
  | "Close"
  | "Complete"
  | "CreatePurchaseOrder"
  | "Delete"
  | "ExportToPdf"
  | "ExportLabel"
  | "Open"
  | "Prepare"
  | "ReOpen"
  | "Restore"
  | "SystemQrCode"
  | "SubmitRequisition"
  | "Update"
  | "Trigger";

const DELETE = "Delete";
const CANCEL = "Cancel";
const RESTORE = "Restore";
const UPDATE = "Update";
const EXPORTTOPDF = "ExportToPdf";
const SIGN = "Sign";
const COPY = "Copy";
const SWITCHFORM = "SwitchForm";
const BARCODE = "Barcode";
const SYSTEMQRCODE = "SystemQrCode";
const EMAIL = "Email";
const IMPORTCSV = "ImportCsv";
const EXPORTTOCSV = "ExportToCsv";
const CREATEFOLLOWUP = "CreateFollowUp";
const deletedRecordActions = [
  EXPORTTOPDF,
  EXPORTTOCSV,
  EMAIL,
  BARCODE,
  RESTORE,
];
const commonActions = [EXPORTTOPDF, SIGN, COPY, SWITCHFORM];
const destructiveActions = [DELETE, CANCEL, RESTORE, UPDATE];
const statusActions = [
  "Approve",
  "Close",
  "Complete",
  "Open",
  "Reject",
  "ReOpen",
  "ReSchedule",
];

const canBulk = (action: BulkActionType, records: Record[]) =>
  R.all((r) => R.includes(action, r.actions || []), records);

const singleRecordActions = (
  context: Context,
  records: Record[],
  entity: Entity,
  includeSwitchForm: boolean,
) => {
  const { entities, forms } = context;
  const record = records[0];
  const recordIsArchived = record.properties.isDeleted;
  const actionsOnRecord = record.actions || [];

  const entityForms = forms.filter(
    (f) => f.entityName === entity.name && f.id !== record.properties.formId,
  );

  const scheduledEventEntity = getScheduledEvent(entities, entity);

  const recordActions = recordIsArchived
    ? actionsOnRecord.filter(
        (action) => deletedRecordActions.indexOf(action) !== -1,
      ) // filter only actions allowed for archived
    : [
        scheduledEventEntity &&
          canDo(scheduledEventEntity, "Create") &&
          "ScheduledWO",
        includeSwitchForm && entityForms.length && SWITCHFORM,
        canDo(entity, "Create") && entity.type !== "SubEntity" && COPY,
        canDo(entity, "Create") &&
          behaveAs("FollowUp", entity) &&
          CREATEFOLLOWUP,
        ...actionsOnRecord,
      ];

  const actions: string[] = [
    behaveAs(BARCODE, entity) && BARCODE,
    ...recordActions,
  ];

  return R.reject((action) => !action, actions);
};

const someRecordsAreArchived = (records: Record[]) =>
  records.some((r) => r.properties.isDeleted);

const getCommonActions = (records: Record[], entity: Entity): string[] => [
  canBulk(EXPORTTOPDF, records) && "ExportToPdfBulk",
  canBulk(SYSTEMQRCODE, records) && "SystemQrCodeBulk",
  behaveAs(BARCODE, entity) && "BarcodeBulk",
  canBulk(RESTORE, records) && "RestoreBulk",
];

const getActionsBasedOnConditions = (
  containsArchived: boolean,
  entities: Entities,
  entity: Entity,
  records: Record[],
): string[] => {
  if (containsArchived) {
    return [];
  }

  const scheduledEventEntity = getScheduledEvent(entities, entity);

  return [
    scheduledEventEntity &&
      canDo(scheduledEventEntity, "Create") &&
      "ScheduledWO",
    canBulk("Open", records) && "OpenBulk",
    canBulk("ReOpen", records) && "ReOpenBulk",
    canBulk("Update", records) && isFullEntity(entity) && "UpdateBulk",
    canBulk("Close", records) && "CloseBulk",
    canBulk("Complete", records) && "CompleteBulk",
    canBulk("Delete", records) && "DeleteBulk",
    canBulk("SubmitRequisition", records) && "SubmitRequisitionBulk",
    canBulk("ExportLabel", records) && "ExportLabelBulk",
    canBulk("Trigger", records) && "TriggerBulk",
    canBulk("CreatePurchaseOrder", records) && "CreatePurchaseOrder",
  ];
};
const multipleRecordActions = (
  context: Context,
  records: Record[],
  entity: Entity,
) => {
  const { entities } = context;
  const containsArchived = someRecordsAreArchived(records);

  const commonActions = getCommonActions(records, entity);

  const actions = getActionsBasedOnConditions(
    containsArchived,
    entities,
    entity,
    records,
  ).concat(commonActions);

  return R.reject((action) => !action, actions);
};

export const mapStringsToActions = (actions: string[], behaviors: Behavior[]) =>
  actions.reduce((acc: Action[], name) => {
    const handler =
      getActionHandlersByBehaviors(behaviors)[name] || functionHandlers()[name];
    return handler ? acc.concat({ name, label: handler.label }) : acc;
  }, []);

const mapEntityRecordActions = (
  context: Context,
  entity: Entity,
  records: Record[],
  includeSwitchform: boolean,
): Action[] => {
  if (!records.length) return [];

  const actions =
    records.length > 1
      ? multipleRecordActions(context, records, entity)
      : singleRecordActions(context, records, entity, includeSwitchform);
  return mapStringsToActions(actions, entity.behaviors);
};

const isEntityDuplicated = (action: CreateAction, actions: CreateAction[]) =>
  !!R.find(
    (a) => a.column !== action.column && a.entity.name === action.entity.name,
    actions,
  );

const getInBoundActions = (
  entities: Entities,
  entity: Entity,
  behaviorName: BehaviorName,
): CreateAction[] => {
  const inBoundJoins = entity.joins.filter((j) => !j.outbound);
  const createActions = inBoundJoins
    .map(
      (join): CreateAction => ({
        column: join.column,
        entity: entities[join.entityName],
      }),
    )
    .filter(
      ({ entity }) => behaveAs(behaviorName, entity) && canDo(entity, "Create"),
    );

  return createActions.map((action) =>
    R.mergeRight(action, {
      isEntityDuplicated: isEntityDuplicated(action, createActions),
    }),
  );
};

export const getCreateActions = (
  entities: Entities,
  entity: Entity,
  record: Record,
): CreateAction[] => {
  if (entity.type !== "Entity") return [];

  const { id } = record.properties;

  if (behaveAs("Task", entity)) {
    const woEntity = entities[entity.arguments.workOrderEntity];
    return woEntity && canDo(woEntity, "Create")
      ? [{ column: `taskId`, entity: woEntity }]
      : [];
  }

  if (!id || behaveAs("ScheduledEvent", entity) || behaveAs("Request", entity))
    return [];

  const workOrders = getInBoundActions(entities, entity, "WorkOrder");
  const requests = getInBoundActions(entities, entity, "Request");

  return requests.concat(workOrders);
};

const mapCreateActions = (
  context: Context,
  sourceEntity: Entity,
  records: Record[],
) => {
  if (!records.length || records.length > 1) return [];
  if (records[0].properties.isDeleted) return [];

  const actions = getCreateActions(context.entities, sourceEntity, records[0]);

  return actions.map((action) => {
    const { entity, isEntityDuplicated } = action;
    const columnName = action.column;

    const ef = filterFormsByEntity(context.forms, entity.name);
    const qs = createQueryString({
      copyType: "create",
      sourceId: records[0].properties.id,
      sourceEntity: sourceEntity.name,
      targetColumn: columnName,
    });
    const link =
      ef.length > 1
        ? undefined
        : `${getUrlFormId(context, entity.name, ef.length && ef[0].id)}${qs}`;

    const column = getColumn(entity, columnName);

    const columnLabel =
      isEntityDuplicated && column ? ` (${getLocalizedName(column)})` : "";

    return {
      name: "Create",
      target: action,
      label: `${_("Create")} ${getLocalizedName(entity)}${columnLabel}`,
      link,
    };
  });
};

export const mapQueryActions = (query: QueryForEntity, entity: Entity) => {
  if (!query) return [];

  const listActions = [IMPORTCSV, EXPORTTOCSV, EXPORTTOPDF, EMAIL];
  return listActions
    .filter((la) => R.includes(la, entity.commands))
    .map((name) => `${name}List`)
    .map((name) => ({
      name,
      label: (
        getActionHandlersByBehaviors(entity.behaviors)[name] ||
        functionHandlers()[name]
      ).label,
    }));
};

export const removeEmptyGroups = (actionGroups: ActionsGroup[]) =>
  R.reject((g: ActionsGroup) => R.isEmpty(g[1]), actionGroups);

export const getActions = (
  context: Context,
  entity: Entity,
  records: Record[],
  includeSwitchForm: boolean,
  query: QueryForEntity,
  predefinedActions: Action[] = [],
): ActionsGroup[] => {
  if (includeSwitchForm && records.filter((r) => !r.properties.id).length) {
    return [["common", mapStringsToActions([SWITCHFORM], entity.behaviors)]];
  }

  const actions = [
    ...predefinedActions,
    ...mapEntityRecordActions(context, entity, records, includeSwitchForm),
    ...mapCreateActions(context, entity, records),
  ];

  const [common, other] = R.partition(
    (a) => R.includes(a.name, commonActions),
    actions,
  );
  const [destructive, behavior] = R.partition(
    (o) => R.includes(R.split("Bulk", o.name)[0], destructiveActions),
    other,
  );

  const list = mapQueryActions(query, entity);

  const actionGroups: ActionsGroup[] = [
    ["common", common],
    ["behavior", behavior],
    ["list", list],
    ["destructive", destructive],
  ];

  return removeEmptyGroups(actionGroups);
};

export const hasActions = (actionGroups: ActionsGroup[]) =>
  !R.isEmpty(removeEmptyGroups(actionGroups));

export const actionNeedsStatus = (action: string): boolean =>
  R.includes(action, statusActions);
