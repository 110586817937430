import * as R from "ramda";
import { Component, JSX } from "react";
import { defaultFor, update } from "common";
import { filtersApi } from "common/api/filters";
import { recordsApi } from "common/api/records";
import { apiSearchFull } from "common/api/search";
import { Entity } from "common/entities/types";
import { MapWidgetPropsFn } from "common/form/types";
import { canAccessQueryEntities, sameResultSet } from "common/query/common";
import { setFilterExcludingArchived } from "common/query/filter";
import {
  getListQuery as getQuery,
  queryWithAvailableColumns,
} from "common/query/list";
import {
  mapRelatedSummaryToSelect,
  mapSelectToRelatedSummary,
} from "common/query/select";
import { TableConfig } from "common/query/table/types";
import {
  ActionsByRecordId,
  Filter as QueryFilter,
  QueryForEntity,
} from "common/query/types";
import { Context } from "common/types/context";
import { ApiErrorResponse } from "common/types/error";
import { Filter } from "common/types/filters";
import { defaultPageSize } from "common/types/pagination";
import { Output, Unsubscribe } from "common/types/preferences";
import { CancellablePromise } from "common/types/promises";
import { Properties } from "common/types/records";
import { Report } from "common/types/reports";
import { GoFn } from "common/types/url";
import { getStarredFor } from "common/utils/starred";
import { PermissionsError } from "common/widgets/error";
import { getReportLabelByRecord } from "x/layout/navigation/functions";
import { Crumb } from "x/layout/ribbon/breadcrumb";
import { ContentType, defaultValue } from "x/records/list/types";
import { Content } from "../list/content";
import { ContentLoading } from "../list/content-loading";
import {
  getPreferences,
  getPreferencesParams,
  setPreferences,
} from "./functions/preferences";
import {
  getInitialValue,
  getListQuery,
  getQueryWithRelatedFilter,
  getReportWithoutSite,
  hasSecondaryQueries,
} from "./functions/query";

interface PropTypes {
  context: Context;
  report: Report;
  entity: Entity;
  onChangeQuery: (q: QueryForEntity) => any;
  goTo: GoFn;
  withLinks: boolean;
  listFilter?: QueryFilter;
  widgetsMapper?: MapWidgetPropsFn;
  config?: TableConfig;
  crumbs?: Crumb[];
  displayHeader?: boolean;
  newPath?: string;
  newButton?: JSX.Element;
  output?: Output;
  scope?: string;
}

interface StateType {
  page: number;
  pageSize: number;
  totalRecords: number;
  initialLoading: boolean;
  loadingRecords: boolean;
  error: ApiErrorResponse;
  value: ContentType;
  filters: Filter[];
  records: any[];
  actionsByRecordId: ActionsByRecordId;
}

export class RecordListController extends Component<PropTypes, StateType> {
  state: StateType = {
    page: 0,
    pageSize: defaultPageSize,
    totalRecords: 0,
    initialLoading: true,
    loadingRecords: false,
    error: undefined,
    value: undefined,
    filters: [],
    records: [],
    actionsByRecordId: {},
  };
  unsubscribes: Unsubscribe[] = undefined;
  fetchRecordsRequest: CancellablePromise<unknown>;
  fetchFormAndFilterRequest: CancellablePromise<unknown>;
  fetchActionsRequest: CancellablePromise<unknown>;

  componentDidMount() {
    const { context, entity, report, listFilter, scope } = this.props;
    const { preferenceService, starred } = context;

    if (!entity) return;

    this.unsubscribes = [
      preferenceService.subscribe(() => this.forceUpdate()),
      starred.subscribe(() => this.forceUpdate()),
    ];

    this.loadFormAndFilter();

    const { page, pageSize } = this.getPageSettings();
    this.loadRecords(
      getInitialValue(context, entity, report, listFilter, scope),
      page,
      pageSize,
      false,
    );
  }

  componentWillUnmount() {
    if (this.unsubscribes) {
      this.unsubscribes.forEach((unsubscribe) => unsubscribe());
      this.unsubscribes = undefined;
    }
    this.fetchRecordsRequest?.cancel();
    this.fetchFormAndFilterRequest?.cancel();
    this.fetchActionsRequest?.cancel();
  }

  componentDidUpdate(prevProps: PropTypes) {
    const { context, entity, report, listFilter, scope } = this.props;
    const { value = defaultValue, pageSize } = this.state;

    const entityChanged = entity.name !== prevProps.entity.name;
    const filterChanged = !R.equals(listFilter, prevProps.listFilter);

    if (entityChanged || filterChanged) {
      const filterQuery = value?.filter?.query;
      const newValue =
        filterChanged && filterQuery
          ? {
              ...value,
              filter: {
                ...value.filter,
                query: setFilterExcludingArchived(filterQuery, listFilter),
              },
            }
          : getInitialValue(context, entity, report, listFilter, scope);

      this.loadRecords(newValue, 0, pageSize, true);
    }
  }

  getPageSettings = () => {
    const { context, entity, report, scope } = this.props;
    const { page, pageSize } = this.state;
    const { site, preferenceService } = context;

    const prefs = getPreferences(
      preferenceService,
      site,
      entity,
      report,
      scope,
    );

    return { page: prefs?.page ?? page, pageSize: prefs?.pageSize ?? pageSize };
  };

  setListPreferences = (page: number, pageSize: number, value: ContentType) => {
    const { context, entity, report, scope } = this.props;
    const { site, preferenceService } = context;

    setPreferences(
      preferenceService,
      site,
      entity,
      report,
      value,
      page,
      pageSize,
      scope,
    );
  };

  onChange = (newValue: ContentType) => {
    const { context, entity, report, scope } = this.props;
    const { entities, site, preferenceService } = context;
    const { page, pageSize, value: oldValue } = this.state;

    const { widths, filter } = getPreferencesParams(
      preferenceService,
      site,
      entity,
      report,
      newValue,
      scope,
    );
    const newValueWithWidths = {
      ...newValue,
      body: { ...newValue.body, widths },
    };

    this.setState({ value: newValueWithWidths });

    if (
      newValue.output !== oldValue.output ||
      !R.equals(widths, oldValue?.body?.widths || []) ||
      !R.equals(filter, oldValue?.filter)
    ) {
      this.setListPreferences(page, pageSize, newValueWithWidths);
    }

    const getQueryFor = (content: ContentType) => ({
      entity: entity.name,
      query: getQuery(entities, entity, report, content?.filter),
    });

    const secondariesChanged = !R.equals(
      oldValue?.filter?.secondaryQueries,
      newValue?.filter?.secondaryQueries,
    );
    const resultSetChanged = !sameResultSet(
      getQueryFor(oldValue),
      getQueryFor(newValue),
    );

    if (resultSetChanged || secondariesChanged) {
      this.loadRecords(newValue, 0, pageSize, true);
    }
  };

  onError = (error: ApiErrorResponse) => {
    this.setState({
      initialLoading: false,
      loadingRecords: false,
      error,
    });
  };

  onReload = () => {
    const { value, page, pageSize } = this.state;
    this.loadRecords(value, page, pageSize, true);
  };

  onChangePage = (newPage: number) => {
    this.loadRecords(this.state.value, newPage, this.state.pageSize, true);
  };

  onChangePageSize = (newPageSize: number) => {
    this.loadRecords(this.state.value, 0, newPageSize, true);
  };

  onToggleStar = (id: string, isStarred: boolean) => {
    const { context, entity } = this.props;
    const { starred, site } = context;

    if (isStarred) {
      starred.star(site.name, entity.name, id);
    } else {
      starred.unstar(site.name, entity.name, id);
    }
  };

  onSaveFilter = (filter: Filter, isNew: boolean) => {
    const { context } = this.props;
    const { apiCall } = context;
    const { filters, value, page, pageSize } = this.state;

    const newFilter = {
      ...filter,
      query: mapSelectToRelatedSummary(filter.query),
      secondaryQueries: filter.secondaryQueries,
    };

    const existing = R.find((f) => f.id === filter.id, filters);

    return isNew
      ? filtersApi(apiCall)
          .create(newFilter)
          .then(({ id }) => {
            const filterWithId = { ...filter, id };
            const newValue = { ...value, filter: filterWithId };

            this.setState({
              filters: [filterWithId].concat(filters),
              value: newValue,
            });

            this.setListPreferences(page, pageSize, newValue);

            return id;
          })
      : existing
        ? filtersApi(apiCall)
            .update(newFilter)
            .then(() =>
              this.setState({
                filters: update(existing, filter, filters),
                value: { ...value, filter },
              }),
            )
        : CancellablePromise.reject("Error saving filter");
  };

  onRemoveFilter = () => {
    const { context } = this.props;
    const { filters, value } = this.state;
    const { filter } = value;

    return filter?.id
      ? filtersApi(context.apiCall)
          .remove(filter.id)
          .then(() => {
            this.setState({
              filters: filters.filter((f) => f.id !== filter.id),
              value: { ...value, filter: undefined },
            });
            this.onReload();
          })
      : CancellablePromise.reject("No filter id");
  };

  loadFormAndFilter = () => {
    const { context, entity } = this.props;
    const { entities } = context;

    this.fetchFormAndFilterRequest = filtersApi(context.apiCall)
      .listForEntity(entity.name)
      .then((filters) => {
        const validFilters = filters.reduce((acc, filter) => {
          const hasAccessToEntities = canAccessQueryEntities(entities, {
            entity: filter.entity,
            query: filter.query,
          });

          return hasAccessToEntities
            ? acc.concat({
                ...filter,
                query: mapRelatedSummaryToSelect(
                  queryWithAvailableColumns(entities, entity, filter.query),
                ),
              })
            : acc;
        }, []);

        this.setState({ filters: validFilters });
      })
      .catch(this.onError);
  };

  getRecordsLoadErrorHandler =
    (value: ContentType) => (error: ApiErrorResponse) => {
      if (this.state.initialLoading && value?.filter) {
        // Load records for a default filter in case of error
        this.loadRecords(defaultValue, 0, defaultPageSize, false);
      } else {
        this.onError(error);
      }
    };

  loadActions = (data: Properties[]) => {
    const { context, entity } = this.props;

    this.fetchActionsRequest = recordsApi(context.apiCall)
      .getRecordsActions(
        entity.name,
        data.map((record) => record.id),
      )
      .then((actionsByRecordId) => this.setState({ actionsByRecordId }));
  };

  loadRecords = (
    value: ContentType,
    page: number,
    pageSize: number,
    withSetPreferences: boolean,
  ) => {
    const { context, entity, report, onChangeQuery } = this.props;
    const entityName = entity.name;
    const { apiCallFull, entities } = context;
    const { filter = defaultFor<Filter>() } =
      value || defaultFor<ContentType>();

    const query = {
      entity: entityName,
      query: getQuery(entities, entities[entityName], report, filter),
    };

    onChangeQuery(query);

    const newValue = value?.body?.selected
      ? { ...value, body: { ...value.body, selected: undefined } }
      : value;

    this.setState({
      loadingRecords: true,
      page,
      pageSize,
      value: newValue,
      error: undefined,
    });

    const listQuery = hasSecondaryQueries(value)
      ? getQueryWithRelatedFilter(entities, query, newValue)
      : report?.query
        ? query
        : getListQuery(entities, query);

    const fetchRecordsApi = report
      ? apiSearchFull(apiCallFull).runRawQueryWithPagination
      : apiSearchFull(apiCallFull).runQueryWithPagination;

    this.fetchRecordsRequest = fetchRecordsApi(
      listQuery,
      context,
      page,
      pageSize,
    )
      .then(({ data: { data = [], fullCount }, status }) => {
        if (status === 205) {
          // if page was not found, we reset it to 0
          this.loadRecords(value, 0, pageSize, withSetPreferences);
        }

        if (withSetPreferences) {
          this.setListPreferences(page, pageSize, newValue);
        }

        this.setState({
          initialLoading: false,
          loadingRecords: false,
          records: data,
          totalRecords: fullCount,
        });

        return data;
      })
      .then(this.loadActions)
      .catch(this.getRecordsLoadErrorHandler(value));
  };

  render() {
    const {
      context,
      entity,
      report,
      withLinks,
      goTo,
      widgetsMapper,
      config,
      crumbs,
      displayHeader,
      newPath,
      newButton,
      output,
      scope,
    } = this.props;
    const { site, scope: applicationScope, entities } = context;
    const {
      initialLoading,
      loadingRecords,
      error,
      page,
      totalRecords,
      pageSize,
      records,
      actionsByRecordId,
      filters,
      value,
    } = this.state;

    if (!entity) return <PermissionsError />;
    if (initialLoading)
      return (
        <ContentLoading
          scope={applicationScope}
          shouldSetDocumentTitle={!scope}
        />
      );

    const includeSiteCol = site.isGroup && entity.recordScope === "SingleSite";

    // to not see site by default at the records list view
    const entitiesWithoutSite = !includeSiteCol
      ? {
          ...entities,
          [entity.name]: {
            ...entity,
            columns: entity.columns.filter((c) => c.name !== "site"),
          },
        }
      : entities;

    const reportWithoutSite =
      report && !includeSiteCol ? getReportWithoutSite(report) : report;

    const { uiFormat } = context;
    const reportName = getReportLabelByRecord(uiFormat.culture, report);

    const defaultQuery = getQuery(
      entitiesWithoutSite,
      entity,
      reportWithoutSite,
      undefined,
    );

    // TODO: refactor content (take out ribbon and modal)
    return (
      <Content
        context={context}
        entity={entity}
        defaultQuery={defaultQuery}
        starred={getStarredFor(context.starred.get(), site.name, entity.name)}
        withLinks={withLinks}
        reportName={reportName}
        widgetsMapper={widgetsMapper}
        config={config}
        // Api call state
        loadingRecords={loadingRecords}
        error={error}
        filters={filters}
        records={records}
        actionsByRecordId={actionsByRecordId}
        page={page}
        totalRecords={totalRecords}
        pageSize={pageSize}
        // Value and handlers
        value={value}
        onChange={this.onChange}
        onReload={this.onReload}
        onSaveFilter={this.onSaveFilter}
        onRemoveFilter={this.onRemoveFilter}
        onToggleStar={this.onToggleStar}
        onChangePage={this.onChangePage}
        onChangePageSize={this.onChangePageSize}
        goTo={goTo}
        crumbs={crumbs}
        displayHeader={displayHeader}
        newPath={newPath}
        newButton={newButton}
        output={output}
        scope={scope}
      />
    );
  }
}
