import { JSX } from "react";
import { behaveAs, getRelatedEntities } from "common/entities";
import { Entity } from "common/entities/types";
import { StandardValue } from "common/record/types";
import { BehaviorName } from "common/types/behaviors";
import { Context } from "common/types/context";
import { ExtraValidationResponse } from "common/record/form/form";
import { getLocalizedName } from "common";
import { getRelatedRecordsByRelation } from "../functions";

const getSubEntityFn =
  (relatedBehaviorName: BehaviorName) => (main: Entity, related: Entity) =>
    behaveAs("ApprovalGroup", main) &&
    behaveAs(relatedBehaviorName, related) &&
    related.arguments.approvalGroupEntity === main.name;

const isApprovalCostCenterEntity = getSubEntityFn("ApprovalCostCenter");
const isApproverEntity = getSubEntityFn("Approver");
const isRequisitionerEntity = getSubEntityFn("Requisitioner");
const isRequisitionerGroupEntity = getSubEntityFn("RequisitionerGroup");

const behaviors: BehaviorName[] = [
  "ApprovalCostCenter",
  "Approver",
  "Requisitioner",
];

export const validateRelatedEntities = (
  context: Context,
  entity: Entity,
  value: StandardValue,
): ExtraValidationResponse => {
  const { entities } = context;

  const costCenterRecords = getRelatedRecordsByRelation(
    entity,
    entities,
    value,
    isApprovalCostCenterEntity,
  );
  const approverRecords = getRelatedRecordsByRelation(
    entity,
    entities,
    value,
    isApproverEntity,
  );
  const requisitionerRecords = getRelatedRecordsByRelation(
    entity,
    entities,
    value,
    isRequisitionerEntity,
  );
  const requisitionerGroupRecords = getRelatedRecordsByRelation(
    entity,
    entities,
    value,
    isRequisitionerGroupEntity,
  );

  const requiredEntities = getRelatedEntities(
    entity,
    context.entities,
    (relatedEntity) =>
      behaviors.some((behavior) => behaveAs(behavior, relatedEntity)),
  ).map((entity) => entity.localizedName);

  const validationMessage: JSX.Element[] = [
    <span key="missingRelatedRecords">
      {_(
        "{ENTITY} requires these related entities to have records: {REQUIRED_ENTITIES}",
      )
        .replace("{ENTITY}", getLocalizedName(entity))
        .replace("{REQUIRED_ENTITIES}", requiredEntities.join(", "))}
    </span>,
  ];

  const isValid = !!(
    costCenterRecords.length &&
    approverRecords.length &&
    (requisitionerRecords.length || requisitionerGroupRecords.length)
  );

  return {
    isValid,
    validationMessage,
  };
};
