import { Properties } from "common/types/records";
import { Filter } from "common/query/types";

export type GoFn = (id?: string | number) => void;

type CopyType = "create" | "clone" | "follow-up";

export type CopySettings = {
  copyType?: CopyType;
  sourceEntity?: string;
  sourceId?: string;
  sourceIds?: string[];
  targetColumn?: string;
  extraProperties?: Properties;
};

export interface QueryString {
  defaultProperties?: Properties;
  hidePanels?: boolean;
  listFilters?: Filter;
  reference?: boolean;
  requestId?: string;
  tenant?: string;
  token?: string;
  update?: boolean;
  url?: string;
  userName?: string;
  siteName?: string;
  entity?: string;
  xtoken?: string;
  sso?: boolean;
  auditTrailId?: string;
  tab?: string;
  action?: string;
  copyType?: CopyType;
  sourceId?: string;
  sourceIds?: string[];
  sourceEntity?: string;
  targetColumn?: string;
}

export const queryStringKeys: Array<keyof QueryString> = [
  "defaultProperties",
  "hidePanels",
  "listFilters",
  "reference",
  "requestId",
  "tenant",
  "token",
  "update",
  "url",
  "userName",
  "siteName",
  "entity",
  "xtoken",
  "sso",
  "auditTrailId",
  "tab",
  "action",
  "copyType",
  "sourceEntity",
  "sourceId",
  "sourceIds",
  "targetColumn",
];
export const keysToNotParse: Array<keyof QueryString> = [
  "siteName",
  "tenant",
  "entity",
];

export interface Location {
  pathname: string;
  queryString: QueryString;
  search?: string; // stringified query string
}
