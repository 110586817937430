import { getFkId } from "common/functions/foreign-key";
import { Record } from "common/types/records";
import { createQueryString } from "common/app/query-string";
import { getUrl } from "common/entities";
import { Entity } from "common/entities/types";
import { filterFormsByEntity } from "common/functions/forms";
import { Context } from "common/types/context";
import { CopySettings, GoFn, QueryString } from "common/types/url";

export const getScheduledWorkOrderAssetIds = (
  scheduledWorkOrderEntity: Entity,
  record: Record,
): string[] => {
  const eventAssetEntity =
    scheduledWorkOrderEntity?.arguments?.eventAssetEntity;
  const relatedEntity = record?.related?.[eventAssetEntity] || [];
  return relatedEntity.map((r) => getFkId(r.properties.assetId));
};

export const getUrlFormId = (
  context: Context,
  entityName: string,
  formId: number,
): string => {
  const { site, forms, entities } = context;
  const entity = entities[entityName];
  const url = getUrl(entity, site && site.name);

  const entityForms = filterFormsByEntity(forms, entity && entity.name);

  const form =
    formId || entityForms.length ? `/${formId || entityForms[0].id}` : "";

  return `${url}/new${form}`;
};

export const getRedirectUrl = (
  context: Context,
  targetEntity: Entity,
  formId: number,
  copySettings?: CopySettings,
) => {
  if (!targetEntity?.name) return undefined;

  const { copyType, sourceEntity, sourceId, targetColumn, extraProperties } =
    copySettings || {};

  const urlFormId = getUrlFormId(context, targetEntity.name, formId);
  const queryString = createQueryString({
    copyType,
    sourceEntity,
    sourceId,
    targetColumn,
    defaultProperties: extraProperties,
  });

  return `${urlFormId}${queryString}`;
};

export const redirect = (
  context: Context,
  goTo: GoFn,
  targetEntity: Entity,
  formId: number,
  copySettings?: CopySettings,
) => {
  if (!goTo || !targetEntity?.name) return;

  const url = getRedirectUrl(context, targetEntity, formId, copySettings);

  if (url) goTo(url);
};

export const getCopySettingsFromUrl = (queryString: QueryString) => {
  const {
    copyType,
    sourceEntity,
    sourceId,
    sourceIds,
    targetColumn,
    defaultProperties,
  } = queryString;

  return {
    copyType,
    sourceEntity,
    sourceId,
    sourceIds,
    targetColumn,
    extraProperties: defaultProperties,
  };
};
