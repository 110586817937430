import * as R from "ramda";
import { Context } from "common/types/context";
import { getLocalizedName } from "common";
import { recordsApi } from "common/api/records";
import { searchApi } from "common/api/search";
import { Record } from "common/types/records";
import { createQueryString } from "common/app/query-string";
import { getUrl } from "common/entities";
import { Entity } from "common/entities/types";
import {
  showInfoNotification,
  showErrorNotification,
} from "common/ui/notification";
import { getScheduledEvent } from "common/record/actions/functions/behaviors";
import { toSiteRecords } from "common/record/actions/functions/record";
import { isSelectField } from "common/query/types";
import { ActionFnHandlers } from "common/record/actions/types";
import { openUrlInNewTab } from "common/utils/window-location";

export const genericCatch = () =>
  showErrorNotification(_("We could not complete your request"));

export const getTitle = (entity: Entity, record: Record): string => {
  const select = entity?.query?.select;
  const titleField = R.find(
    (s) => s.alias && s.alias === "title",
    select.filter(isSelectField),
  );
  return titleField
    ? record?.properties?.[titleField.name]
    : record?.properties?.id;
};

export const functionHandlers = (): ActionFnHandlers => ({
  ExportToPdfBulk: {
    label: _("Export selected to PDF"),
    fn: (context: Context, entity: Entity, records: Record[]) => {
      showInfoNotification(_("Your pdf download will start shortly"));
      recordsApi(context.apiCall)
        .bulkDownloadPdf(
          entity,
          records.map((r) => r.properties.id),
        )
        .catch(genericCatch);
    },
  },
  SystemQrCode: {
    label: _("Export as QR Code"),
    fn: (context: Context, entity: Entity, records: Record[]) => {
      showInfoNotification(_("Your QR code download will start shortly"));
      const title = getTitle(entity, records[0]);
      const siteRecords = toSiteRecords(context, [records[0]]);
      recordsApi(context.apiCall)
        .getSystemQrCodes(entity, siteRecords, title)
        .catch(genericCatch);
    },
  },
  SystemQrCodeBulk: {
    label: _("Export selected as QR Codes"),
    fn: (context: Context, entity: Entity, records: Record[]) => {
      showInfoNotification(_("Your QR codes download will start shortly"));
      const siteRecords = toSiteRecords(context, records);
      recordsApi(context.apiCall)
        .getSystemQrCodes(entity, siteRecords)
        .catch(genericCatch);
    },
  },
  Barcode: {
    label: _("Get Barcode"),
    fn: (context: Context, entity: Entity, records: Record[]) => {
      showInfoNotification(_("Your barcode download will start shortly"));
      recordsApi(context.apiCall)
        .getBarcode(
          entity,
          records[0].properties.number,
          records[0].properties.id,
        )
        .catch(genericCatch);
    },
  },
  BarcodeBulk: {
    label: _("Export selected as Barcodes"),
    fn: (context: Context, entity: Entity, records: Record[]) => {
      showInfoNotification(_("Your barcode download will start shortly"));
      recordsApi(context.apiCall)
        .getBarcodes(
          entity,
          records.length,
          records.map((r) => r.properties.id),
        )
        .catch(genericCatch);
    },
  },
  Copy: {
    label: _("Copy/Add"),
    fn: (context, entity, records = [], goTo) => {
      const properties = records[0]?.properties;
      const formId = properties?.formId;
      const fId = formId ? "/" + formId : "";

      const qs = createQueryString({
        copyType: "clone",
        sourceEntity: entity.name,
        sourceId: properties?.id,
      });

      const url = `${getUrl(entity, context.site.name)}/new${fId}${qs}`;
      goTo(url);
    },
  },
  ImportCsvList: {
    label: _("Download Csv template"),
    fn: (context, entity) =>
      recordsApi(context.apiCall).downloadCsvTemplate(entity),
  },
  ScheduledWO: {
    label: _("Create Scheduled Work Order"),
    fn: (context, entity, records, goTo) => {
      const { entities, site } = context;
      const ids = records.map((r) => r.properties.id);
      const scheduledEvent = getScheduledEvent(entities, entity);

      const qs = createQueryString({
        copyType: "create",
        sourceEntity: entity?.name,
        sourceIds: ids,
      });
      const url = `#/${site.name}/${scheduledEvent.name}/new${qs}`;
      goTo(url);
    },
  },
  ExportToCsvList: {
    label: _("Export List To Csv"),
    fn: ({ apiCall }, entity, _records, _goTo, query) =>
      searchApi(apiCall).downloadCsv(getLocalizedName(entity), query),
  },
  ExportToPdfList: {
    label: _("Export List To Pdf"),
    fn: ({ apiCall }, entity, _records, _goTo, query) =>
      searchApi(apiCall).downloadPdf(
        `${getLocalizedName(entity)} - list`,
        query,
      ),
  },
  ViewInLiveAsset: {
    label: _("View in LIVE-Asset"),
    fn: (_context, _entity, records: Record[]) => {
      const properties = records[0]?.properties;
      const liveAssetUrl = properties?.liveAssetUrl;

      openUrlInNewTab(liveAssetUrl);
    },
  },
});
