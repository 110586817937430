import { hasBehavior } from "common/api/behavior";
import { getRelatedEntities } from "common/entities";
import { Entities, Entity } from "common/entities/types";
import { injectFieldsByBehavior } from "common/query/common";
import {
  getRulesMapper,
  setFilterExcludingArchived,
} from "common/query/filter";
import { mergeJoins } from "common/query/joins";
import {
  getListQuery as getQuery,
  hasColumn,
  queryWithAvailableColumns,
} from "common/query/list";
import { addToSelectQuery, setSelect } from "common/query/select";
import {
  Filter as QueryFilter,
  isFieldRule,
  isSelectField,
  QueryForEntity,
  SelectField,
} from "common/query/types";
import { Context } from "common/types/context";
import { Report } from "common/types/reports";
import { getPreferences } from "x/records/list-controller/functions/preferences";
import { ContentType, defaultValue } from "x/records/list/types";

const getRequiredFields = (entity: Entity) => {
  const requiredFieldsBase = entity.isViewAvailableForSidePanel
    ? []
    : [{ name: "formId" }, { name: "isDeleted" }];
  return hasBehavior(entity.behaviors, "Signature")
    ? requiredFieldsBase.concat([{ name: "signature" }])
    : requiredFieldsBase;
};

// TODO there's already a getListQuery imported here. refactor.
export const getListQuery = (
  entities: Entities,
  queryForEntity: QueryForEntity,
): QueryForEntity => {
  const newQueryForEntity = injectFieldsByBehavior(entities, queryForEntity);
  const requiredFields = getRequiredFields(entities[queryForEntity.entity]);
  const newQuery = addToSelectQuery(requiredFields, newQueryForEntity?.query);

  return {
    entity: queryForEntity?.entity,
    query: newQuery,
  };
};

export const getInitialValue = (
  context: Context,
  entity: Entity,
  report: Report,
  listFilters: QueryFilter,
  scope: string,
): ContentType => {
  const { entities, site, preferenceService } = context;

  const prefs = getPreferences(preferenceService, site, entity, report, scope);
  const prefsFilter = prefs?.lastFilter?.filter;
  const prefsQuery = prefsFilter?.query;

  const output = prefs?.output || defaultValue.output;
  const widths = prefs?.lastFilter?.widths;
  const query = setFilterExcludingArchived(
    (prefsQuery?.select &&
      queryWithAvailableColumns(entities, entity, prefsQuery)) ||
      getQuery(entities, entity, report, undefined),
    listFilters,
  );

  // query select can contain site column only if the site is a group
  const updatedQuery =
    site.isGroup || !hasColumn("site", query.select)
      ? query
      : setSelect(
          (query.select || []).filter((s) => {
            return isSelectField(s) && s.name === "site" ? false : true;
          }),
          query,
        );

  return {
    ...defaultValue,
    output,
    filter: { ...defaultValue.filter, ...prefsFilter, query: updatedQuery },
    body: { ...defaultValue.body, widths },
  };
};

export const getQueryWithRelatedFilter = (
  entities: Entities,
  query: QueryForEntity,
  value: ContentType,
): QueryForEntity => {
  const listQuery = getListQuery(entities, query).query;
  const secondaryQueries = value?.filter?.secondaryQueries;
  const relatedEntities = getRelatedEntities(entities[query.entity], entities);

  const newQueryParts = Object.keys(secondaryQueries).reduce(
    (acc, relatedEntityName) => {
      const secondary = secondaryQueries[relatedEntityName];

      if (!secondary) return acc;

      const relatedEntity = relatedEntities.find(
        (e) => e.name === relatedEntityName,
      );
      const relatedPath = `/${relatedEntity.name}.${relatedEntity.ownerFkColumn}`;
      const getFilterWithRelatedPaths = getRulesMapper((rule) =>
        rule && isFieldRule(rule)
          ? { ...rule, path: `${relatedPath}${rule.path ?? ""}` }
          : undefined,
      );

      return {
        joins: [
          ...acc.joins,
          {
            column: relatedEntity.ownerFkColumn,
            entity: relatedEntity.name,
            joins: secondary.joins,
          },
        ],
        groups: [
          ...acc.groups,
          { name: relatedEntity.ownerFkColumn, path: relatedPath },
        ],
        filters: [...acc.filters, getFilterWithRelatedPaths(secondary.filter)],
      };
    },
    { joins: [], groups: [], filters: [] },
  );

  return {
    entity: query.entity,
    query: {
      ...listQuery,
      joins: mergeJoins(newQueryParts.joins, listQuery.joins),
      group: [...(listQuery?.group ?? []), ...newQueryParts.groups],
      filter: {
        and: [listQuery?.filter, ...newQueryParts.filters].filter((f) => !!f),
      },
    },
  };
};

export const getReportWithoutSite = (report: Report) => {
  const query = report.query;
  return {
    ...report,
    query: setSelect(
      query.select.filter((f: SelectField) => f.name !== "site"),
      query,
    ),
  };
};

export const hasSecondaryQueries = (filter: ContentType) => {
  const secondaryQueries = filter?.filter?.secondaryQueries;
  return (
    secondaryQueries && Object.values(secondaryQueries).some((value) => !!value)
  );
};
