import { Component, createRef, JSX } from "react";
import * as R from "ramda";
import { ValidationWarning } from "common/record/form/form-validation-warning";
import { getLayoutAndDrilldownColumns } from "common/form/functions/layout";
import { defaultFor } from "common";
import { focusElement } from "common/html";
import { isReferenceEntity } from "common/api/entities";
import { Entity } from "common/entities/types";
import { FormFooter, LoadingButtonLabels } from "common/form/footer";
import {
  getValidationResults,
  getValidationWarningMessages,
  isFormValid,
} from "common/form/functions/validation";
import { keepOwnCultureFirst } from "common/form/reference-group/functions";
import { FormValidation, Layout } from "common/form/types";
import { merge2 } from "common/merge";
import { RunQuery } from "common/query/types";
import { Content } from "common/record/form/content";
import {
  getDefaultFormTab,
  hasPendingChanges,
  isDelete,
  onFormSave,
  retrieveCommentsCount,
} from "common/record/form/functions";
import { isOverview } from "common/record/utils";
import { RequestOptions } from "common/types/api";
import { Context } from "common/types/context";
import { CancellablePromise } from "common/types/promises";
import { Properties, Record } from "common/types/records";
import { GoFn } from "common/types/url";
import { ValueProps } from "common/with-value-for";
import { type AlertRef } from "common/widgets/alert/types";
import { type FieldRefs } from "common/types/html";
import { RecordHeader } from "../header";
import { auditTrail, documents, generalInfo, Sidebar } from "../sidebar";
import { SidebarElement } from "../sidebar/types";
import type { Reload, StandardUiValue, StandardValue } from "../types";
import { ReadOnlyReports } from "./content/detail/view/reports";

export interface ExtraValidationResponse {
  isValid: boolean;
  validationMessage: JSX.Element[];
}

type ExtraValidation = (
  context: Context,
  entity: Entity,
  value: StandardValue,
) => ExtraValidationResponse;

interface PropTypes extends ValueProps<StandardValue> {
  context: Context;
  entity: Entity;
  withLinks: boolean;
  runQuery: RunQuery;
  reload: Reload;
  auditTrailId: string;
  layout: Layout;
  saving: boolean;
  loading?: boolean;
  disableEdit?: boolean;
  disableReason?: string;
  deleteLabels?: LoadingButtonLabels;
  saveLabels?: LoadingButtonLabels;
  displaySidebar?: boolean;
  displayHeader?: boolean;
  save: (
    record: Record,
    confirmDelete: boolean,
    requestOptions?: RequestOptions,
  ) => CancellablePromise<any>;
  onCancel: () => any;
  onHasChanged?: (isDirty: boolean) => any;
  onDelete?: () => void;
  extraValidation?: ExtraValidation;
  createExtraActionButton?: (isValid: boolean) => JSX.Element;
  goTo?: GoFn;
  getUrl?: (entity: Entity, site: string) => string;
}

const defaultUiValue = defaultFor<StandardUiValue>();
const defaultRecord = defaultFor<Record>();

interface StateType {
  formValidation: FormValidation;
  commentsCount: number;
  showValidationWarning: boolean;
  fieldRefs: FieldRefs;
  validationWarningMessageRef: AlertRef;
}

export class RecordContentForm extends Component<PropTypes, StateType> {
  static readonly displayName = "RecordContentForm";
  fetchCommentsCountRequest: CancellablePromise<unknown>;

  constructor(props: PropTypes) {
    super(props);

    const fieldRefs: FieldRefs = {};
    const validationWarningMessageRef: AlertRef = createRef();
    getLayoutAndDrilldownColumns(props.layout).forEach(
      (field) => (fieldRefs[field] = createRef()),
    );

    this.state = {
      formValidation: undefined,
      commentsCount: 0,
      showValidationWarning: false,
      fieldRefs,
      validationWarningMessageRef,
    };
  }

  componentDidMount() {
    const { context, entity, value } = this.props;

    this.fetchCommentsCountRequest = retrieveCommentsCount({
      context,
      entity,
      value,
      updateCommentsCount: this.updateCommentsCount,
    });
  }

  componentWillUnmount() {
    this.fetchCommentsCountRequest?.cancel();
  }

  onFormValidationChange = (formValidation: FormValidation) => {
    this.setState({ formValidation });
  };

  updateCommentsCount = (count: number) => {
    this.setState({ commentsCount: count ?? 0 });
  };

  scrollToFieldLabel = (fieldName: string) =>
    focusElement(this.state.fieldRefs, fieldName);

  showValidationWarningAndScroll = () => {
    this.setState({ showValidationWarning: true });

    return new Promise<void>((resolve) => {
      setTimeout(() => {
        const { validationWarningMessageRef } = this.state;
        if (validationWarningMessageRef?.current) {
          validationWarningMessageRef.current.scrollIntoView({
            behavior: "smooth",
          });
        }
        resolve();
      }, 500);
    });
  };

  onChangeContent = (newValue: StandardValue) => {
    const { save, reload, onCancel, onHasChanged, value, onChange } =
      this.props;
    const oldRecord = value.record;
    const newRecord = newValue.record;
    const newUi = newValue.ui;

    if (!R.equals(oldRecord, newRecord)) {
      // if the record change, we must SAVE!
      // TODO: we could
      //   .then(() => { this.setValue(e.target.value); reload(); })
      // when adding related, otherwise just do a
      //   .then(() => this.setValue(e.target.value))
      save(newRecord, isDelete(newRecord)).then(() => {
        onChange(newValue);
        if (onHasChanged) {
          onHasChanged(false); // reset dirty flag
        }
        reload();
      });
      return;
    }

    if (!newRecord.properties.id && !newUi?.detail?.form) {
      onCancel();
      return;
    }

    if (
      // if editing related, allow editing detail as well
      newUi?.related?.isDirty &&
      !newUi?.detail?.form
    ) {
      onChange(
        R.set(
          R.lensPath(["ui", "detail", "form"]),
          newRecord.properties,
          newValue,
        ),
      );
    } else {
      onChange(newValue);
      if (onHasChanged) {
        onHasChanged(hasPendingChanges(newUi));
      }
    }

    if (onHasChanged && hasPendingChanges(newUi)) {
      onHasChanged(true);
    }
  };

  onChangeHeader = (properties: Properties) => {
    const { onHasChanged, value, onChange } = this.props;
    const { ui = defaultUiValue } = value;

    onChange(merge2("record", "properties", properties, value));
    onHasChanged(hasPendingChanges(ui));
  };

  onChangeSidebar = (sidebar: SidebarElement) => {
    const { value, onChange } = this.props;

    if (sidebar.label === generalInfo?.label) {
      this.showValidationWarningAndScroll();
    }

    onChange(merge2("ui", "sidebar", sidebar, value));
  };

  getContent = (
    editing: boolean,
    isValid: boolean,
    layout: Layout,
    content: StandardValue,
    classColName: string,
    warningMessages: JSX.Element[] = [],
  ) => {
    const {
      context,
      entity,
      runQuery,
      auditTrailId,
      reload,
      saving,
      onCancel,
      withLinks,
      onDelete,
      deleteLabels,
      saveLabels,
      disableEdit,
      goTo,
      value,
      createExtraActionButton,
      onHasChanged,
      save,
      onChange,
      getUrl,
    } = this.props;
    const {
      formValidation,
      showValidationWarning,
      fieldRefs,
      validationWarningMessageRef,
    } = this.state;

    const onSave = () => {
      onFormSave({ value, save, onHasChanged, reload, onChange });
      this.setState({ showValidationWarning: false });
    };

    return (
      <div className={`${classColName} x-form-content-wrapper`}>
        {editing &&
        showValidationWarning &&
        warningMessages.length &&
        value?.ui?.sidebar?.label === generalInfo?.label ? (
          <ValidationWarning
            validationWarningMessageRef={validationWarningMessageRef}
            headerMessage={_(
              "The provided input is invalid. Please review the following details:",
            )}
            warningMessages={warningMessages}
          />
        ) : undefined}

        <Content
          fieldRefs={fieldRefs}
          context={context}
          runQuery={runQuery}
          entity={entity}
          layout={layout}
          withLinks={withLinks}
          auditTrailId={auditTrailId}
          reload={reload}
          saving={saving}
          updateRecordOnChange={false}
          disableEdit={disableEdit}
          formValidation={formValidation}
          onFormValidationChange={this.onFormValidationChange}
          goTo={goTo}
          onCommentsCountChange={this.updateCommentsCount}
          value={content}
          onChange={this.onChangeContent}
          getUrl={getUrl}
        />
        {editing && (
          <FormFooter
            isNew={!value?.record?.properties?.id}
            isValid={isValid}
            canCancel={true}
            canSave={true}
            onSave={onSave}
            onCancel={onCancel}
            isSaving={saving}
            canDelete={value?.record?.actions?.includes("Delete")}
            onDelete={onDelete}
            isDeleting={false}
            canRestore={false}
            onRestore={undefined}
            isRestoring={false}
            deleteLabels={deleteLabels}
            saveLabels={saveLabels}
            extraActionButton={createExtraActionButton?.(isValid)}
          />
        )}
      </div>
    );
  };

  getLayout = () => {
    const { context, entity, layout } = this.props;

    if (!isReferenceEntity(entity)) return layout;

    // reorder and require current lang
    const ownCulture = context.uiFormat.culture;
    const sortedColumns = keepOwnCultureFirst(
      layout.groups[0].columns,
      ownCulture,
      { required: true },
    );
    return {
      ...layout,
      groups: [{ ...layout.groups[0], columns: sortedColumns }],
    };
  };

  getDefaultSidebarElement = () => {
    const { entity, value, auditTrailId } = this.props;
    const { record = defaultRecord } = value;
    const { properties } = record;

    const defaultTab = getDefaultFormTab();
    if (defaultTab === documents.label)
      return { ...documents, entity: entity.name };

    if (auditTrailId && properties.id) return auditTrail;

    return generalInfo;
  };

  render() {
    const {
      context,
      disableEdit,
      disableReason,
      entity,
      runQuery,
      layout,
      loading,
      withLinks,
      value,
      displaySidebar = true,
      displayHeader = true,
      extraValidation = R.always({ isValid: true, validationMessage: [] }),
    } = this.props;
    const { formValidation } = this.state;

    if (loading) return null;

    const { onlyGroups } = layout;
    const { record = defaultRecord, ui = defaultUiValue } = value;
    const { properties } = record;

    const defaultSidebar = this.getDefaultSidebarElement();
    const content = !ui.sidebar // if no sidebar, inject default into value
      ? { ...value, ui: { ...ui, sidebar: defaultSidebar } }
      : value;

    const editing = !!(ui.detail?.form || ui.related?.isDirty);

    const classCol = displaySidebar ? "col-lg-9" : "col-lg-12";

    const updatedLayout = this.getLayout() || layout;

    const validationResults = getValidationResults(
      entity,
      updatedLayout,
      value?.ui?.detail?.form,
      formValidation,
    );
    const warningMessages = getValidationWarningMessages(
      entity,
      validationResults,
      this.scrollToFieldLabel,
    );

    const {
      isValid: isExtraValidationValid,
      validationMessage: extraValidationWarningMessages,
    } = extraValidation(context, entity, value);

    const isValid =
      !editing ||
      (isFormValid(
        entity,
        updatedLayout,
        formValidation,
        value?.ui?.detail?.form,
      ) &&
        isExtraValidationValid);

    const stats =
      isOverview(content.ui.sidebar.label) &&
      !content.ui.detail?.form &&
      layout.reports?.section?.length ? (
        <div className={`${classCol} x-record-stats`}>
          <ReadOnlyReports
            entityName={entity.name}
            context={context}
            runQuery={runQuery}
            reports={layout.reports.section}
          />
        </div>
      ) : null;

    if (onlyGroups) {
      return this.getContent(
        editing,
        isValid,
        updatedLayout,
        content,
        classCol,
      );
    }

    return (
      <div className="x-padding-20">
        {displayHeader ? (
          <>
            <RecordHeader
              context={context}
              entity={entity}
              layout={layout.header}
              withLinks={withLinks}
              disableEdit={disableEdit}
              disableReason={disableReason}
              value={properties}
              onChange={this.onChangeHeader}
            />
            <hr />
          </>
        ) : undefined}
        <div className="row x-form-content-container">
          {displaySidebar ? (
            <div className="col-lg-3 x-form-content-sidebar">
              <Sidebar
                context={context}
                layout={layout}
                entity={entity}
                record={record}
                ui={ui}
                disabled={false}
                editing={editing}
                isGeneralInformationInvalid={
                  !!warningMessages.length ||
                  !!extraValidationWarningMessages.length
                }
                commentsCount={this.state.commentsCount}
                value={content.ui.sidebar}
                onChange={this.onChangeSidebar}
              />
            </div>
          ) : undefined}
          {stats}
          {this.getContent(
            editing,
            isValid,
            updatedLayout,
            content,
            classCol,
            warningMessages.concat(extraValidationWarningMessages),
          )}
        </div>
      </div>
    );
  }
}
