import * as R from "ramda";
import { isPropertiesObject } from "common/api/records";
import { searchApi } from "common/api/search";
import { behaveAs, getColumn, getColumns } from "common/entities";
import {
  isRelatedSiteDataColumn,
  isRelatedUserDataColumn,
  looseColumnNameCheck,
} from "common/entities/entity-column/functions";
import { Entities, Entity } from "common/entities/types";
import { isValidDynamicValue } from "common/form/dynamic-values";
import { createDefaultLayout } from "common/form/functions/layout";
import {
  EXTRA_PREFIX,
  getCommonEntityFields,
  getOverwritableColumns,
} from "common/form/group/functions";
import { Group } from "common/form/types";
import { isForeignKey } from "common/functions/foreign-key";
import { getFormByIdOrEntity } from "common/functions/forms";
import { merge1 } from "common/merge";
import { addFilter } from "common/query/filter";
import { addToSelectQuery } from "common/query/select";
import {
  isSelectField,
  Query,
  QueryForEntity,
  Select,
} from "common/query/types";
import { Context } from "common/types/context";
import { ForeignKey } from "common/types/foreign-key";
import { Form } from "common/types/forms";
import { CancellablePromise } from "common/types/promises";
import { KeysOf, Properties, PropertyValue } from "common/types/records";

export const getFormOrDefault = (
  context: Context,
  entityName: string,
  formId?: number,
  defaultProperties?: Properties,
): Form => {
  const form = getFormByIdOrEntity(context.forms, entityName, formId);
  if (form) return form;
  const entity = context.entities[entityName];
  if (!entity) return undefined;
  const layout = createDefaultLayout(entity, context, defaultProperties, false);
  return { entityName, settings: layout, labels: {}, sites: undefined };
};

export const hasSystemCalendar = (entity: Entity) =>
  R.any(
    (c) => c.dataType === "systemintfk" && c.name === "calendarId",
    entity.columns,
  );

export const addDefaultCalendarId = (
  context: Context,
  entity: Entity,
  defaultProperties: Properties,
) => {
  const defaultCalendar = R.find(
    (c) => c.settings.isDefault,
    context.calendars,
  );

  return defaultCalendar && hasSystemCalendar(entity)
    ? merge1("calendarId", defaultCalendar.id, defaultProperties)
    : defaultProperties;
};

const hasIdSelect = (select: Select) =>
  R.any((field) => isSelectField(field) && field.name === "id", select);

const getQueryWithId = (query: Query) =>
  hasIdSelect(query.select)
    ? query
    : merge1("select", [{ name: "id" }, ...query.select], query);

export const getResolveQueries = (
  context: Context,
  groups: Group[],
  entity: Entity,
  defaultProperties: Properties,
): ResolveQueries =>
  R.keys(defaultProperties).reduce((acc: ResolveQueries, key: string) => {
    const defaultValue = defaultProperties[key];
    const column = getColumn(entity, key);

    const relativeEntity = column?.relatedEntity
      ? context.entities[column.relatedEntity]
      : undefined;
    const isRelative = isValidDynamicValue(
      column?.dataType,
      relativeEntity,
      defaultValue,
    );

    if (
      !column?.isForeignKey ||
      isForeignKey(defaultValue) ||
      isRelatedSiteDataColumn(column) ||
      isRelatedUserDataColumn(column) ||
      isRelative
    ) {
      return acc;
    }

    const relatedEntity = context.entities[column.relatedEntity];

    const querySelect = getCommonEntityFields(
      defaultProperties,
      column,
      groups,
      entity,
      relatedEntity,
    );

    const defaultQuery: QueryForEntity = addFilter(
      { name: "id", op: "eq", value: defaultValue },
      {
        entity: relatedEntity.name,
        query: getQueryWithId(relatedEntity.query),
      },
    );

    const query = merge1(
      "query",
      addToSelectQuery(querySelect, defaultQuery.query),
      defaultQuery,
    );

    return merge1(key, query, acc);
  }, {});

export const resolveForeignKeys = (
  context: Context,
  entity: Entity,
  form: Form,
  defaultProperties: Properties,
): CancellablePromise<Properties> => {
  const groups = form && form.settings.groups;
  const runQuery = searchApi(context.apiCall).runQueryFkExpansion;
  const resolveQueries = getResolveQueries(
    context,
    groups,
    entity,
    defaultProperties,
  );

  const promises: Array<CancellablePromise<ForeignKey>> = R.values(
    resolveQueries,
  ).map((q) => runQuery(q).then((records: ForeignKey[]) => records[0]));

  return CancellablePromise.all(promises).then((fks) =>
    R.zipObj(R.keys(resolveQueries) as KeysOf<Properties>, fks),
  );
};

interface ResolveQueries {
  [columnName: string]: QueryForEntity;
}

const isId = (value: any) => R.is(String, value) || R.is(Number, value);

const isBlacklistedColumn = (
  sourceEntity: Entity,
  targetEntity: Entity,
  columnName: string,
) =>
  behaveAs("Asset", sourceEntity) &&
  (behaveAs("Request", targetEntity) || behaveAs("WorkOrder", targetEntity)) &&
  looseColumnNameCheck(columnName, "description");

export const omitBlacklistedColumns = (
  sourceEntity: Entity,
  targetEntity: Entity,
  properties: Properties,
) =>
  behaveAs("Asset", sourceEntity) &&
  (behaveAs("Request", targetEntity) || behaveAs("WorkOrder", targetEntity))
    ? R.pickBy(
        (_, column) => !looseColumnNameCheck(column, "description"),
        properties,
      )
    : properties;

export const getExtraPropsPartialForValue = (
  entities: Entities,
  entity: Entity,
  properties: Properties,
  columnName: string,
  newValue: PropertyValue,
): Properties => {
  if (!entity || !columnName) return {};

  const column = getColumn(entity, columnName);
  const simplePartial = { [columnName]: newValue };

  if (!newValue || !column.isForeignKey || !isPropertiesObject(newValue))
    return simplePartial;

  const sourceEntity = entities[column.relatedEntity];

  const partial = {
    [columnName]: {
      id: isId(properties[columnName]) ? properties[columnName] : undefined,
      ...newValue,
    },
  };

  const columnNames = getColumns(entity).map((c) => c.name);
  const overwritableColumns = getOverwritableColumns(entity);

  return (R.keys(newValue) as KeysOf<Properties>)
    .filter(R.startsWith(EXTRA_PREFIX))
    .reduce((acc, key) => {
      const keyName = key.replace(EXTRA_PREFIX, "");

      if (isBlacklistedColumn(sourceEntity, entity, keyName)) return acc;

      const columnName = columnNames?.find((c) =>
        looseColumnNameCheck(keyName, c),
      );
      const isOverwritable = R.includes(columnName, overwritableColumns);
      const canOverwrite =
        columnName &&
        (!properties || R.isNil(properties[columnName]) || isOverwritable);

      return canOverwrite ? merge1(columnName, newValue[key], acc) : acc;
    }, partial);
};

export const getRelatedAndCustomCommonKeys = (
  relatedKeys: string[] = [],
  propertiesKeys: string[] = [],
) => {
  return relatedKeys.reduce((acc: string[], key: string) => {
    if (key.indexOf("extra_") !== 0) return acc;

    const propName = propertiesKeys?.find((p) =>
      looseColumnNameCheck(R.replace("extra_", "", key), p),
    );
    return propName ? acc.concat(propName) : acc;
  }, []);
};
