import * as R from "ramda";
import { behaveAs } from "common/entities";
import { Entity } from "common/entities/types";
import {
  getDynamicDefaultsToResolve,
  getNonDynamicDefaults,
} from "common/form/defaults";
import { resolveDynamicValue } from "common/form/dynamic-values";
import { merge1, mergeChain } from "common/merge";
import { getMatchingProperties } from "common/record/properties";
import { FormValue, StandardValue } from "common/record/types";
import { addTempIdToRecord, removeSystemColumns } from "common/record/utils";
import { Context } from "common/types/context";
import { Form } from "common/types/forms";
import { CancellablePromise } from "common/types/promises";
import { Properties, Record, RelatedRecords } from "common/types/records";

function mergeDefaultsAndTaskProps(
  defaultValue: StandardValue,
  relatedEntitiesMapped: RelatedRecords,
  mappedProperties: Properties,
  nonDynamicDefaults: Properties,
  resolvedFormDefaults: Properties = {},
): FormValue {
  // The order is important here
  // 1. nonDynamicDefaults
  // 2. resolvedFormDefaults
  // 3. mappedProperties : Overwrites form defaults
  const propertiesMappedWithWoDefaults = {
    ...nonDynamicDefaults,
    ...resolvedFormDefaults,
    ...mappedProperties,
  };

  return mergeChain(defaultValue)
    .prop("ui")
    .prop("detail")
    .set("form", propertiesMappedWithWoDefaults)
    .up()
    .prop("related")
    .set("form", relatedEntitiesMapped)
    .output();
}

export function mapWorkOrderTaskRelatedRecords(
  entities: Entity[],
  record: Record,
) {
  return entities.reduce((acc: RelatedRecords, entity) => {
    const {
      arguments: { targetEntity },
      name,
    } = entity;
    const records = record.related[name].map((r, i) => {
      r.properties = R.keys(r.properties).reduce((acc, key) => {
        const newKey = key === "c_step" ? "step" : key;
        return R.mergeRight(acc, { [newKey]: r.properties[key] });
      }, {});
      return addTempIdToRecord(removeSystemColumns(r), `${i + 1}`);
    });
    return merge1(targetEntity, records, acc);
  }, {});
}

export function getWorkOrderTaskDependencies(
  context: Context,
  entity: Entity,
  taskEntity: string,
  taskId: string,
  taskRecord: Record,
  defaultValue: StandardValue,
  form: Form,
): CancellablePromise<FormValue> {
  const defaults = form?.settings?.defaults ?? {};
  const entities = R.values(context.entities);
  const taskItemEntities = R.filter(
    (e) => behaveAs("TaskItem", e) && e.arguments.ownerEntity === taskEntity,
    entities,
  );

  if (!taskItemEntities.length || !taskId)
    return CancellablePromise.resolve(defaultValue);

  const relatedEntitiesMapped = mapWorkOrderTaskRelatedRecords(
    taskItemEntities,
    taskRecord,
  );
  const mappedProperties = {
    ...getMatchingProperties(entity, taskRecord.properties, form),
    taskId: taskId,
  };
  const { defaultsWithDynamicValues, promiseArray } =
    getDynamicDefaultsToResolve(context, entity, defaults);
  const nonDynamicDefaults = getNonDynamicDefaults(
    defaults,
    defaultsWithDynamicValues,
  );

  if (!promiseArray.length) {
    return CancellablePromise.resolve(
      mergeDefaultsAndTaskProps(
        defaultValue,
        relatedEntitiesMapped,
        mappedProperties,
        nonDynamicDefaults,
      ),
    );
  }

  return CancellablePromise.all(promiseArray)
    .then((data: Record[][]) => {
      const resolvedFormDefaultsWithDynamicValues =
        defaultsWithDynamicValues.reduce((acc, object, index: number) => {
          const { key, dataType, value, entity } = object;

          const newValue = resolveDynamicValue(
            dataType,
            entity,
            value,
            context,
            data[index],
          );

          return R.mergeRight(acc, { [key]: newValue });
        }, {});

      return mergeDefaultsAndTaskProps(
        defaultValue,
        relatedEntitiesMapped,
        mappedProperties,
        nonDynamicDefaults,
        resolvedFormDefaultsWithDynamicValues,
      );
    })
    .catch(() => {
      return mergeDefaultsAndTaskProps(
        defaultValue,
        relatedEntitiesMapped,
        mappedProperties,
        nonDynamicDefaults,
      );
    });
}
