import * as R from "ramda";
import { Form } from "common/types/forms";
import { Entity } from "common/entities/types";
import { EntityColumn } from "common/entities/entity-column/types";
import { getLayoutGroupColumns } from "common/form/functions/common";
import { looseColumnNameCheck } from "common/entities/entity-column/functions";
import { Properties } from "common/types/records";

export const getMatchingProperty = (
  properties: Properties,
  columnName: string,
): Properties => {
  const propertyName = Object.keys(properties ?? {}).find((name) =>
    looseColumnNameCheck(name, columnName),
  );
  return properties?.[propertyName];
};

const filterFormFields = (form: Form, properties: Properties) => {
  const formFieldNames = getLayoutGroupColumns(form?.settings.groups)?.map(
    (groupColumn) => groupColumn.columnName,
  );
  return R.pick(formFieldNames, properties);
};

export const getMatchingProperties = (
  entity: Entity,
  properties: Properties,
  form?: Form,
) => {
  if (!entity || !properties) return undefined;

  const entityProps = entity.columns.reduce(
    (acc: Properties, col: EntityColumn) => {
      const value = getMatchingProperty(properties, col.name);
      return R.isNil(value) || col.readOnly || col.unique
        ? acc
        : { ...acc, [col.name]: value };
    },
    {},
  );

  return form ? filterFormFields(form, entityProps) : entityProps;
};
